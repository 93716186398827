@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply relative z-1 bg-whiten font-satoshi text-base font-normal text-body;
  }
}

@layer components {
}

@layer utilities {
}

:root {
  --color-table-row-even: #f7f9fc;
}

/* third-party libraries CSS */
tbody tr:nth-child(even) {
  background: var(--color-table-row-even);
}

/* custom drop shadow */
.drop-1 {
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
}

.form-control {
  width: 100% !important;
}

/* scrollbar hidden  */
.no-scrollbar {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Custom Arrow Styling */
.slick-prev,
.slick-next {
  width: auto !important;
  height: auto !important;
  background: none;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
}

.slick-track {
  display: flex !important;
}

/* custom scrollbar */
/* Hide scrollbar for Chrome, Safari and Opera */
/* .no-scrollbar::-webkit-scrollbar {
    display: none;
    }
     */
/* Hide scrollbar for IE, Edge and Firefox */
/* .no-scrollbar {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    } */

/* styles.css */

/* scrollbar design */
* {
  scrollbar-width: thin;
  /* scrollbar-width: none; */
  scrollbar-color: #30343836 #f5f5f5;
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 20px;
}
*::-webkit-scrollbar-thumb {
  background-color: #acacac;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
}

/* scrollbar design */
body {
  font-family: 'Satoshi', sans-serif;
}

.font-sans {
  font-family: ui-sans-serif, system-ui;
}

.font-serif {
  font-family: Georgia, ui-serif, serif;
}

.font-mono {
  font-family: Menlo, ui-monospace, monospace;
}
